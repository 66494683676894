@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: var(--novel-white);
}

/* Style for paragraphs */
.markdown-content p {
  margin-top: 0;
  margin-bottom: 10px; /* Adjust space between paragraphs */
  font-size: 1.0em; /* Or 'initial' to reset to browser defaults */
}

.markdown-content h1 {
  font-size: 2.0em; /* 32px if base is 16px */
  margin-top: 24px;
  margin-bottom: 16px;
}

.markdown-content h2 {
  font-size: 1.75em; /* 28px if base is 16px */
  margin-top: 22px;
  margin-bottom: 14px;
}

.markdown-content h3 {
  font-size: 1.5em; /* 24px if base is 16px */
  margin-top: 20px;
  margin-bottom: 12px;
}

.markdown-content h4 {
  font-size: 1.25em; /* 20px if base is 16px */
  margin-top: 18px;
  margin-bottom: 10px;
}

.markdown-content h5 {
  font-size: 1.0em; /* 16px if base is 16px */
  margin-top: 16px;
  margin-bottom: 8px;
}

.markdown-content h6 {
  font-size: 0.875em; /* 14px if base is 16px */
  margin-top: 14px;
  margin-bottom: 6px;
}

/* Unordered lists */
.markdown-content ul {
  list-style-type: disc; /* Bullet style */
  padding-left: 20px; /* Indentation of list */
}

/* Ordered lists */
.markdown-content ol {
  list-style-type: decimal; /* Numbering style */
  padding-left: 20px; /* Indentation of list */
}

/* List items */
.markdown-content li {
  margin-bottom: 5px; /* Space between list items */
}

/* Links */
.markdown-content a {
  color: #0077cc; /* Color for links */
  text-decoration: underline; /* Underline links for visibility */
}

/* Code blocks and inline code */
.markdown-content code {
  font-family: monospace;
  padding: 2px 4px; /* Padding inside code blocks */
}

.markdown-content pre {
  padding: 10px; /* Padding around code blocks */
  overflow-x: auto; /* Allows scrolling for long lines */
}

/* Blockquotes */
.markdown-content blockquote {
  border-left: 4px solid #ddd; /* Left border for distinction */
  padding-left: 16px; /* Spacing from the border */
  color: #666; /* Grey text color for contrast */
  margin-left: 0; /* Align with the main content */
  margin-right: 0; /* Align with the main content */
}

.markdown-content img {
  max-width: 35%;
  min-width: 100px;  /* Ensures images are not too small on large screens */
  height: auto;
  display: block;
  margin: 10px auto;
}
